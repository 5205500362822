.contenedor-contactos-logo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.contenedor-contactos-logo a img{
    max-width: 6vw;
    margin: 0px 4vw;
}

.contenedor-contactos-logo a img:hover {
    border-radius: 20%;
    padding: 6px;
}