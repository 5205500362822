.contenedor-padre-preguntas{
    margin: 3vh 10vw;
    font-family: var(--font-all);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white !important;
}
.contenedor-padre-preguntas h1 {
    color: var(--color-fondo);
    font-size: 30px;
    margin: 20px 0px;
    font-weight: 800;
}
.contenedor-padre-preguntas a{
    color: var(--color-rosa);
}
.contenedor-padre-preguntas a:hover {
    color: var(--color-verde);
    font-weight: 600;
}
.ul-items li{
text-decoration: dashed;
list-style: auto;
text-align: start;
font-size: 18px;
}
.ul-itemsv3{
        padding: 20px 0px;
}
.ul-itemsv3 li {
    text-decoration: dashed;
    list-style: none;
    text-align: start;
    font-size: 18px;
}
.ul-itemsv2 li {
    text-decoration: dashed;
    list-style: auto;
    text-align: start;
    font-size: 16px;
}

.contenedor-social{
    padding: 20px;
}

.contenedor-img-pagos{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-pagos{
    max-width: 35vw;
}

.img-cuentas-pf{
    width: 30px;
    padding: 3px;
}


@media (max-width: 1200px) {
    .contenedor-padre-preguntas {
        justify-content: start;
    }
}