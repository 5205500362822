.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


::-webkit-scrollbar {
    width: 0.5vw !important;
  margin: 0px;
  padding: 0px;
}
::-webkit-scrollbar-track {
  background: #1a1819;

}

::-webkit-scrollbar-thumb {
  background: var(--color-rosa);
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
    width: 5px !important;

}