.formulario-admin-datos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.contenedor-admin-max{
min-height: 100vh;
}

.formulario-contenedor-admin-datos{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    margin: 19px;
background-color: black;
padding: 20px;
}