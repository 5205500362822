.contenedor-covert-info-padre img{
    width: 100%;
    height: 35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

@media (max-width: 1200px) {
   .contenedor-covert-info-padre img {
             height: 14vh;
   }
}