body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-blanco:#fefefe;
  --color-rosa:#ff0042;
   --color-verde:#01ec96;
   --color-fondo:#1a1819;
  /* background-image:url(./img/Fondo-Blazer.jpg); */
  background-image:url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2FFondo-Blazer.jpg?alt=media&token=ce04dd2a-4c3d-467e-8aee-a5db77332d60);

  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position: center; */
  background-size: cover;
  min-height: 100vh;
  max-width: 99.5vw !important;
  --font-all:'Noto Sans JP',
  sans-serif;
  font-family:'Noto Sans JP',
  sans-serif !important;
  overflow-y: scroll;
  scroll-behavior: smooth !important;
}
html {
  scroll-behavior: smooth !important;
}
.fondo{
  margin: 0;
  font-family: -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-rosa:#dc0c42;
  --color-verde:#01ec96;
  --color-fondo:#1a1819;
  --font-all:'Noto Sans JP',
  sans-serif;
  background-image:url(./img/inicio2.jpg);
  background-position: center
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400&family=Train+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-colorTextSecondary {
  color: rgb(255 255 255 / 54%) !important;
}
.MuiTypography-body2{
  font-family: 'Roboto Mono',
  monospace !important;
  font-weight: 500 !important;
      font-size: 13px !important;
}
.MuiTypography-h5{
      font-size: 15px !important;
  font-family: "Roboto" ,
  "Helvetica",
  "Arial",
  sans-serif;
  font-weight: 600 !important;
  line-height: 1.334 !important;
}
.MuiCardContent-root{
  padding: 5px !important;
}
.MuiCardActions-root{
  padding: 7px !important;
}

.MuiAvatar-img{
  object-fit: unset !important;
}

.MuiCardMedia-root{
      background-size: contain !important;
}

.MuiCardHeader-root{
  flex-direction: column !important;
   padding: 0px !important;
}
.MuiCardActions-root{
  justify-content: center;
}

.btn-primary {
  background-color: #000000 !important;
  border-color: var(--color-rosa) !important;
  font-family: 'Roboto Mono';
}

.makeStyles-root-1{
  border-radius: 9px !important;
}

.MuiSlider-root {
color: var(--color-rosa) !important;
}

.navbar-light .navbar-nav .nav-link{
  color: white ;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link{
  color: var(--color-rosa) !important;
}

.navbar-dark .navbar-nav .nav-link{
  color: white !important;
}

@media (max-width: 1200px) {
  .MuiStepper-root{
        width: 43vw;
  }
  body{
    background-position: center;
  }
}


.carousel-control-prev-icon{
  background-image: url("./img/reshot-icon-right-arrow-button-YAB8GEM7SD-c5f61.svg") !important;
  transform: rotate(180deg) !important;
}
.carousel-control-next-icon{
  background-image: url("./img/reshot-icon-right-arrow-button-YAB8GEM7SD-c5f61.svg") !important;
}
