.horizontal {
    display:block;
    float:left;
    padding:0 10px;
    
}
.horizontal a:hover{
 color: var(--color-verde) !important;
}
.ul{
    align-items: center;
    display: inline-flex;
}

.text-white-nav{
    color: var(--color-rosa) !important;
}

.text-white {
    color: white !important;
}

.icon__social{
    margin-top: 10px;
    font-size: 30px;
}

.color-ngro li a{
 color: var(--color-fondo) !important;

}

.color-ngro li a:hover{
    color: var(--color-rosa) !important;

}

.contenedor-socials {
    display: flex;
}
.contenedor-socials-noflex-none{
    display: flex;

}


@media (max-width: 1200px){
    .contenedor-socials {
        display: none !important;
    }
}
