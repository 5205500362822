.mrauto{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
}
.mrauto a{
    
    text-decoration: none;
    color: var(--color-blanco);
    padding: 10px;
    font-size: 18px;
    font-family: var(--font-all);
    font-weight: 400;
    /* text-transform: uppercase; */
}
.mrauto a:hover {
    text-decoration: none;
    color: var(--color-rosa);
    padding: 10px;
}
.mrauto h1{
font-family: var(--font-all);
font-size: 2.5vh;
}
.boton-login-navbar h1{
font-family: var(--font-all);
font-weight: 600;
font-size: 2vh;
background: #ff0042;
padding: 1vh;
border-radius: 9px;
letter-spacing: 1px;
}

.letraBlanca{
    color: white ;
    font-family: var(--font-all) !important;
}

.color-navbar{
    background-color: #000000 !important;
    border-bottom: 2px solid var(--color-rosa);
}
.boton-login-navbar{
    color: var(--color-blanco);
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}
.boton-login-navbar:hover {
    text-decoration: none;
    color: black;
}

.modal-login .modal-content {
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 48%, rgba(0, 0, 0, 0.8911939775910365) 99%); */
    background: white;
}
.modal-login{
    min-width: 100vw !important;
    min-height: 100vh !important;
}
.hover-foto:hover {
    background: var(--color-rosa) !important;
    padding: 1px;
    border-radius: 50%;
}

.logo-izquierda{
    display: flex;
    flex-direction: row;
}