.footerConteiner {
  color: aliceblue;
  padding: 25px;
  padding-bottom: 0px;
}
.textMidle {
  font-size: 11px;
  font-weight: 100;
  font-weight: 400;
  color: rgb(87, 87, 87);
}
.textMidle a{
  
  color: rgb(87, 87, 87);
}
.footer {
  background-color: #0d0d0d;
  height: 105px;
  
}

.copyright {
  display: flex;
  justify-content: center;
}
.socialFooter {
  display: flex;
  justify-content: center;
  
}

.footer__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desarrollado {
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .footer {
    height: 100%;
  }
  .socialFooter {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
