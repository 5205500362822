.login-container{
    /* background: radial-gradient(circle, rgba(191, 46, 46, 0) 2%, rgba(232, 70, 70, 0) 48%, rgba(221, 101, 101, 0.891) 99%); */
    padding: 4vw;
}

.login-container h1 {
     text-decoration: none;
     color: white;
     padding: 10px;
         font-family: var(--font-all);
letter-spacing: 1px;
     font-weight: 600;
     text-transform: uppercase;
}

.login-container h3 {
    text-decoration: none;
        color :white;
    font-size: 14px;
    font-family: var(--font-all);
    font-weight: 400;
}

.btn-log{
    cursor: pointer;
    color: var(--color-rosa);
}
.btn-log:hover{
    
    color: #ff0044c7;
}

.politicas-uso{
    color: var(--color-rosa);
    cursor: pointer;
}
.politicas-uso:hover{
    color: #ff0044c7;
   
}