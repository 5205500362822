.inputRange-precios{
    padding: 0px 3vw;
    width: 40vw;
}

.titulo-slieder{
    /* font-size: 13px !important; */
  font-size: 2vh !important;
   color: white;
   font-weight: 400;
   cursor: pointer;
}

.contenedor-silder-titulo{
    display: flex;
    flex-direction: column;
}
@media (max-width: 1200px) {
    .titulo-slieder {
        font-size: 0.5vh !important;
    }


}