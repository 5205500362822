.contenedor-pagar-abuelo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-all);
    font-weight: 300 !important;
    min-height: 100vh;
}


.contenedor-pagar-padre {
    background: white;
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    box-shadow: 1px 1px 19px 3px black;
}
.contenedor-pagar-padre h1{
    font-family: var(--font-all);
    font-size: 4vh;
    color: black;

}
.card-item-pagar{
border-top: 1px solid rgba(73, 73, 73, 0.72);
margin-top: 3vh;
padding-top: 1vh;
display: flex;
flex-direction: row;
/* align-items: center; */
}
.card-item-pagar img{
    max-width: 7vw;
    max-height: 6vw;
}
.card-item-pagar img:hover {
    transform: scale(1.3);
}

.contenedor-data-item{
    padding: 2vh 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.contenedor-data-item h1{
    font-size: 2vh;
}
.contenedor-data-item h2 {
    font-size: 2vh;
}

.contenedor-data-item h3 {
    font-size: 1.7vh;
    color: var(--color-rosa);
    font-weight: 300;
}
.contenedor-data-item h4 {
    font-size: 1.7vh;
    color: var(--color-verde);
    font-weight: 300;
}

.contenedor-data-item h6 {
    font-size: 1.3vh;
}


.contenedor-subtotal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contenedor-descuento h1 {
   font-size: 2vh !important;
   color: rgba(255, 0, 0, 0.514);
}

.contenedor-descuento h2 {
    font-size: 2vh !important;
    color: rgba(255, 0, 0, 0.514);
}

.contenedor-descuento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contenedor-izquierda{
    padding: 3vw;
}
.contenedor-izquierda h2{
    font-size: 2vh;
    padding: 5px;
}

.contenedor-derecha {
    padding: 3vw;
}

.contenedor-derecha h1{
    font-size: 2.5vh;
    padding: 1vh;
}
.contenedor-subtotal h1 {
    font-size: 2.5vh;
}
.contenedor-subtotal h2 {
    font-size: 3.5vh;
    font-weight: 700;
}

.contenedor-cupon{
    padding: 1vw;
}

.contenedor-cupon h2{
    font-size: 16px;
}

.contenedor-finalizar-detalle{
    display: flex;
    flex-direction: row;
}


@media (max-width: 1200px) {
    .contenedor-pagar-padre h1 {
        font-size: 1.5vh !important;
    }
    .contenedor-subtotal h2 {
        font-size: 2vh;
        font-weight: 300;
    }

    .contenedor-pagar-abuelo {
        min-height: 75 !important;
    }
}