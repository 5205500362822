.ul-items li {
    text-decoration: dashed;
    list-style: none;
    text-align: start;
    font-size: 18px;
}
.contenedor-padre-finalizar-all{
    display: flex;
    flex-direction: column;
}
.contenedor-flex-ro{
display: flex;
flex-direction: row;
}

.detalles-finalizar-compra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.detalles-finalizar-compra h1 {
    font-size: 1.3vw;
}

.item-finalizar-compra {
    border-top: 1px solid rgba(106, 106, 106, 0.72);
    color: rgba(39, 39, 39, 0.72);
    padding-top: .4vh;
}

.item-finalizar-compra h6 {
    font-size: 14px;
}

.items-juntos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contenedor-step-3 h1{
 font-size: 16px;
}
.contenedor-step-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.MuiStepIcon-root.MuiStepIcon-active{
    color: var(--color-rosa) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed{
    color: #3fb548 !important;
}

.MuiButton-containedSecondary{
    background-color: var(--color-rosa) !important;
}

.comprobante {
    border-top: 1px solid rgb(97, 97, 97);
    padding: 3vw;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-all) !important;
}
.comprobante-body{
    padding: 3vw 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.comprobante h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
}

.comprobante h2 {
    font-size: 14px;
    text-align: center;
}



.contenedor-data-item-comprobante h1 {
    font-size: 12px;
}

.contenedor-data-item-comprobante h2 {
    font-size: 16px;
    margin: 0px 10px;
}

.contenedor-data-item-comprobante h3 {
        font-size: 16px;

    color: var(--color-rosa);
    font-weight: 300;
    margin: 0px 10px;

}

.contenedor-data-item-comprobante h4 {
        font-size: 13px;

    color: var(--color-verde);
    font-weight: 300;
    margin: 0px 10px;

}

.contenedor-data-item-comprobante h6 {
    font-size: 13px;
}

.precio-verde-grande span{
font-size: 20px;
font-weight: 500;
color: #3fb548;
padding: 4px;
}

.metodos-pago-head{
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 16px;
}
.parrafo-info-pago{
    font-size: 12px;
}

@media (max-width: 1200px) {
  .detalles-finalizar-compra {
      display: none;
  }
  .comprobante {
      width: 50vw;
  }
  .contenedor-data-item-comprobante h1 {
      font-size: 1vh;
  }
  .contenedor-data-item-comprobante h2 {
      font-size: 1vh;
  }

  .contenedor-data-item-comprobante h4 {
      font-size: 1vh;
  }
  
}