.sticker-container{
    position: relative;
}

.sticker-container:hover .sticker-info{

    display: block;
}
.sticker-container .sticker-info{
    display: none;
    z-index: 10;
    position: absolute;
    top: 10px;
    width: 100%;
    border-radius: 3px;
    border: solid 1px ;
    /* height: 30px; */
    background-color: white;
    font-size: 0.6rem;

}
.sticker-container .sticker-icon{
     display: block; 
    width: 100%;
    display: flex;
    justify-content: center;
       
}
.sticker-container .sticker-icon .sticker{
    position: absolute;
    top: -30px;
}
.sticker-container .sticker-icon img{
     /* display: block; */
     /* position: relative; */
    width: 38px;
    height: 30px;
}

.MuiCardHeader-content{
    display: flex;
    align-items: center;
}

.item-disponible{
    background: #ff0000a6;
    color: #ffffff;
    font-size: 15px;
    font-family: 'Roboto Mono',
    monospace;
    /* width: 195px; */
    position: relative;
    
}
.item-disponible-true{
    background: #003f00a6;
    color: #ffffff;

    /* font-size: 15px; */
    font-family: 'Roboto Mono',
    monospace;

    position: relative;

}

.item-precio{
        color: #000000;
        font-size: 23px;
        font-family: 'Roboto Mono';
        font-weight: 400;
        border-radius: 7px;
        /* display: flex; */
        /* flex-direction: row; */
        /* justify-content: space-between; */
}

/* .item-precio span{
    font-weight: 200;
    font-weight: 400;
} */
.inspect img{
    width: 110%;
    
}
.inspect:hover img{

    background-color: rgb(224, 205, 205);
}

.media{
      height: 0;
      padding-top: 56.25%;
      background-color: linear-gradient(180deg, rgba(0,0,0,0.4598214285714286) 79%, rgba(255,0,0,0.4234068627450981) 91%);
}
.media :hover{
     transform: scale(1.3);
    }

.rootCard{
        padding: 5px;
        max-width: 195px;
        margin: 10px;
        padding: 10px;
        background: "#ffffffbd";
        font-family: "Roboto Mono monospace";
        font-weight: 700 !important;
        text-transform: uppercase;
        color: "#212121";
        text-shadow: 1px 1px #01ec9629;
    }

    .rootCard:hover{
         box-shadow: 1px 4px 20px 3px #212121;
    }
     .rootCard:hover img{
transform: scale(1.3),
     }