.letra-generica{
    font-size: 18px;
    font-weight: 700;
    color: var(--color-rosa);
    letter-spacing: 1px;
}

.letra-generica span{
    font-size: 18px;
    color: var(--color-verde);
}

.fondo-modal{
    background: var(--color-fondo);
}

.letra-Modal-titulo {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-rosa);
    letter-spacing: 1px;
    text-transform: uppercase;
}