.Contenedor-tweet {
  margin: 25px;
  padding: 30px;
  border: 1px solid #ff2828;
  background-color: #161616;
  width: 500px;
}
.tarjeta{
  min-width: 390px !important;
}


.Contenedor {
  display: flex;
  flex-direction: row;
}

@media(max-width:400px){
  .Contenedor-tweet {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .tarjeta {
    min-width: 190px !important;
min-height: auto;

  }
}