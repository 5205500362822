.img__carousel {
    object-fit: fill;
    height: 40vh;
    width: 100%;
}

.carrousel-contenedor-home{
    margin: 0px 0px;
    width: 100vw;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 48%, rgba(0, 0, 0, 0.8911939775910365) 99%); */
}
.carrousel-contenedor-home h1{
    margin: 0;
    font-size: 2em !important;
    font-weight: 500 !important;
    letter-spacing: 0.1em !important;
    color: #fe0040 !important;
    /* text-shadow: 0 0 5px #01ec96, 0 0 20px #01ec96, 0 0 40px #01ec96, 0 0 60px #01ec96; */
    font-family: var(--font-all);
    background: #000000;
    text-transform: uppercase !important;
    padding: 2vh 0px;
}

.carrousel-contenedor-only{
    margin-bottom: 0px;
}
.redes-carrousel-each-twitch {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0vh;
    right: 0vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-twitch:hover {
   padding: 3vh 3vw;
   background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */
.redes-carrousel-each-insta {
    height: 8vh;
     width: 13vw;
    position: absolute;
    bottom: 0vh;
    right: 23vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-insta:hover{
   padding: 3vh 3vw;
   background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */
.redes-carrousel-each-tw {
    z-index: 0;
    height: 8vh;
     width: 13vw;
    position: absolute;
    bottom: 0vh;
    right:8vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-tw:hover{
    padding: 3vh 3vw;
    background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */


.contenedor-de-tweets{
min-height: 100vh;
max-height: 90vh;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
margin: 3vw;
}



@media (max-width: 1200px) {
    .img__carousel {
        height: 20vh;
    }

    .carrousel-contenedor-home {
    }
    .contenedor-de-tweets{
min-height: 50vh;
    }
}