.contenedor-titulo-tienda{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background: #131313; */
}

.titulo-tienda{
    font-size: 50px;
}

.box{
    position: relative;
    width: 400px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box h2{
    color: #fff;
    font-size: 3em;
    font-weight: 800;
    letter-spacing: 0.1em;
    /* text-shadow: 0 0 5px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 60px #01ec96; */
   font-family: 'Train One',
   cursive;
    text-transform: uppercase;
}
.lightbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    border-radius: 10px;
    background: #fff;
    z-index: 2;
    /* box-shadow: 0 0 10px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 80px #01ec96,
    0 0 120px #01ec96; */
    animation:  animatelightbar 5s linear infinite;
}
@keyframes animatelightbar{
    0%,5%
    {
        transform: scaleY(0) translateX(0);
    }
    10% {
        transform: scaleY(1) translateX(0);
    }
    90% {
        transform: scaleY(1) translateX(calc(400px - 10px));
    }
    95%,100% {
        transform: scaleY(0) translateX(calc(400px - 10px));
    }
}
.topLayer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #131313;
    animation: animatetopLayer 10s linear infinite;
}

@keyframes animatetopLayer {

    0%,
    2.5% {
        transform: translateX(0);
    }

    5%{
        transform:translateX(0);
    }

    45%{
        transform:  translateX(100%);
    }

    47.5%,50%{
        transform: translateX(100%);
    }

    50.001%,52.5%
    {
        transform: translateX(-100%);
    }

     55% {
         transform: translateX(-100%);
     }

     95% {
         transform: translateX(0%);
     }

     97.5%,100% {
         transform: translateX(0%);
     }
}






.titulodePagina-v2  {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}


.titulodePagina-v2 h2{
 position: relative;
 margin: 0;
 padding: 0;
 height: 50px;
overflow: hidden;
}

.titulodePagina-v2 h2 span{
    display: flex;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
}

.titulodePagina-v2 h2:hover span {
    animation: shuffle steps(10) 3s infinite ;
}


@keyframes shuffle {
    0%{
        transform: translateY(0px);
    }
     100% {
         transform: translateY(-500px);
     }
}



.demo h2 {
   font-size: 3em !important;
   font-weight: 500 !important;
   letter-spacing: 0.1em !important;
   color: #313030 !important;
   /* text-shadow: 0 0 5px #01ec96, 0 0 20px #01ec96, 0 0 40px #01ec96, 0 0 60px #01ec96; */
   font-family: 'Montserrat',
   sans-serif;
   text-shadow: 8px 3px #00000042;
   text-transform: uppercase !important;

     /* text-shadow: 0 0 5px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 60px #01ec96; */
}



