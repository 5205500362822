.order-menu{
    
    
    margin:  auto 0px auto -100%;
    @media all and (max-width: 994px){

            font-size: 1.3rem;       
         margin-left: -40px;
        
    }
}


.dropdown  {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    /* color: $text; */
   
    background-color: transparent;
    border: 0;
    width: 100%;


}

.dropdown:hover{
    color: #ff0042;
    /* background-color: $text; */
    font-weight: bold;

}
.dropdown-menu{
      
    /* background-color: $homepage-dark; */
    /* color: $text; */
    cursor: pointer;
    box-shadow: 0px 3px 10px black;
    min-width: 5rem;

}
.nav-menu-items{
    padding: 5px;
}
.nav-menu-items:hover{
                
    color: #ff0042;
    background-color: inherit;
}