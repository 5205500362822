.contenedor-panel{
    padding: 30px;
}
.img-panel{
    width: 100px;
}

.tabla-admin-panel{
    padding: 60px;
}

.contenedor-items-a-subir{
    background: #0000005e;
    padding: 30px;
    margin: 10px 40px;
}