.home-contenedor-padre{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth !important;
   overflow-x: hidden;
}

.contenedor-home-padre-unico{
    display: flex;
    flex-direction: column;
}
.first-item-home {
    z-index: 100;
     width: 100%;
     height: 100%;
     /* background: url(../../img/inicio2.jpg); */
     background: url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2Finicio2.jpg?alt=media&token=4ffa7f83-19b0-49c1-8a1b-41453d12e54e);
     background-repeat: no-repeat;
     background-size: 100%;
     background-position: center;
     }

.first-item-home img{
height: 100vh;
width: 100vw;
z-index: -1;
 background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
}
.first-item-home-difum {
     background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
}

.flecha-bajar{
    position: absolute;
    bottom: 1vh;
    left: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-rosa);
    border-radius: 5px;
     border: 1px solid rgba(0, 0, 0, 0.433);
     padding: 5px;
     box-shadow: 0px 0px 7px 1px;
}
.flecha-bajar:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
    padding: 5px;
}
.subir-sin-estilos{
     /* position: absolute; */
     margin: 30px 0px;
     bottom: 1vh;
     left: 50vw;
     display: flex;
     align-items: center;
     justify-content: center;
     background: var(--color-rosa);
     border-radius: 5px;
     border: 1px solid rgba(0, 0, 0, 0.433);
     padding: 5px;
     box-shadow: 0px 0px 7px 1px;
}
.subir-sin-estilos:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
    padding: 5px;
}

.bajar-sin-estilos{
    text-decoration: none;
    color: black;
}

.bajar-sin-estilos:hover {
    text-decoration: none;
    color: var(--color-rosa);
    cursor: pointer;
}


.background-white-home{
   /* background: #fcfcfc; */
   height: 90vh;
   width: 100vw;
}

.boton-tienda {
    min-width: 5vw;
    position: absolute;
    bottom: 1vh;
    left: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.919);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.433);
    padding: 0.6vh 2vw;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-family: var(--font-all);
    clip-path: polygon(85% 0, 93% 50%, 85% 100%, 9% 100%, 14% 51%, 9% 0);

}

.boton-tienda:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
}



@media (max-width: 1200px) {
    .background-white-home {
   background: #fcfcfc;
        height: 35vh;
    }

    .first-item-home {
        background-position: top;
    }
    .flecha-bajar{
        /* bottom: 60vh; */
        bottom: 60%;
    }
    .boton-tienda{
        /* bottom: 60vh; */
        bottom: 60%;
    }
}

@media (width: 500px) {
.background-white-home {
    height: 34vh;
}
}