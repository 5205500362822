.h1-items-carrito{
    font-size: 12px;
    margin: 0px 1vw;
}

.contenedor-carrito-beta{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1vw;
}

.contenedor-carrito-beta img{
    max-width: 3vw;
}

.boton-eliminar-carrito{
    cursor: pointer;
    padding: 1px;
    border-radius: 3px;
}

.boton-eliminar-carrito:hover {
    background-color: var(--color-rosa);
}

.contenedor-scroll-items-carrito{
    scroll-behavior: smooth;
    overflow-y: scroll;
}
.precio-total-carrito{
    font-size: 20px;
    float: left;
}

.link-drawer {
    text-decoration: none;
    color: #72ab3a;
    font-weight: 500;
}

.link-drawer:hover {
    text-decoration: none;
}