.select-category{
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 2vh 0vw;
}
.nube-tipos{
  display: flex;
  flex-direction: row;
}
.contenedor-tienda{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.contenedor-tienda h3{
  margin-top: 4vh;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
}

.tienda-boton-ver-mas{
  margin: 10px;
}

.tienda-no-results-label{
  font-size: 15px;
  margin: 10vh;
}


.chip-mater{
  padding: 10px 10px !important;
  margin: 10px 10px;
}

.contenedor-carrito{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1vw;
}
.img-item-carrito{
  width: 5vw;
  border-radius: 5px;
  padding: 1vh;
  background-color: rgba(255, 255, 255, 0.83);
}
.img-item-carrito:hover {
  transform: scale(1.5);
  background-color: transparent;
}

.title-item-carrito{
  color: var(--color-blanco);
  font-size: 16px;
}
.precio-item-carrito {
  font-size: 18px;
  color: var(--color-blanco);
}

.carrito-conteiner{
  padding: 30px;
  background: rgba(0, 0, 0, 0.412);
}

.precio-total-carrito {
  padding-top: 20px;
  width: 30vw;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.precio-total-carrito p{
  font-size: 18px;
  padding: 0px 5px;
  font-weight: 500;
}
.precio-total-carrito span {
   font-size: 22px;
   font-weight: 500;
   padding: 5px;
}

.spinner-contenedor{
  min-height: 70vh;
  padding-top: 30vh;
}
.no-more-items-text{
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.img-weapon{
  max-width: 6vw !important;
  /* min-width: 6vw !important; */
}
.img-otro{
  width: 90%;
}

.contenedor-tipos-img {
  max-width: 7vw;
  min-width: 7vw;
  padding: 1vh 1vw;
   margin: 0vh 0.3vw;
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   justify-content: flex-end !important;
   background: #464646;
   border-radius: 2px;
    height: 8vw;
    cursor: pointer;
}
.contenedor-tipos-img h1{
  padding-top:  10px;
  font-size: 2vh;
  color: white;
  font-weight: 400;
    cursor: pointer;

}

.contenedor-tipos-img img {
  /* padding-top: 10px; */
  font-size: 4vw !important;
    cursor: pointer;

}

.contenedor-tipos-img:hover {
  background: var(--color-rosa) !important;
}


.hover-item{
   background: var(--color-rosa) !important;
}



@media (min-width: 1450px) {
  .margin-max-resolution {
    margin: 0px 10vw;
  }
}

.rango-precios-izquierda{
  background: #464646;
  /* padding: 20px; */
  padding-top: 50px;
  border-radius: 2px;
  /* height: 100px; */
  height: 8vw;
   padding: 1vh 1vw;
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   justify-content: flex-end !important;

}
.container-buscador{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.form-cont-search{
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  .contenedor-tipos-img h1 {
    font-size: 0.5vh;
  }

  .contenedor-tipos-img{
    height: 10vw;
  }

  .rango-precios-izquierda {
    padding-top: 10px;
    height: 10vw;
  }

  .img-weapon {
    max-height: 6vh !important;
  }

}