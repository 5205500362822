 
 .input-container{
    margin-bottom: 3px;
 }
 
 input{
    padding: 5px;
  
    /* display: none; */
}

.error-messaje{
    color: red;
}