.contenedor-padre-vender-skins{
    background-color: rgba(0, 0, 0, 0.911);
  padding-top: 5vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid red;
  border-left: 1px solid red;
  font-family: var(--font-all) !important;
      padding: 4vh 0vw;
}
.background-img-vender-skins{
 /* background-image:url(../../img/venderskins.jpeg); */
 background-image: url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2Fvenderskins.jpeg?alt=media&token=2cb0666c-55fd-403f-98ba-cb9efe16dfe0);
 background-repeat: no-repeat;
 background-attachment: fixed;
 background-position: center;
 background-size: cover;
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 85vh;

}
.contenedor-padre-vender-skins a {
    color: var(--color-rosa);
    font-family: var(--font-all);
}
.contenedor-padre-vender-skins h1{
    font-size: 7vh;
color: white;
font-family: var(--font-all);
}

.contenedor-padre-vender-skins p {
    margin: 19px;
    font-size: 3vh;
    color: white;
    font-family: var(--font-all);
}

.contenedor-padre-vender-skins img {
    max-width: 70vw;
}

.pagos-logos{
    max-width: 50vw !important;
}
.achicar-img{
    width: 50vw;
}


@media (max-width: 1200px) {
    .contenedor-padre-vender-skins h1 {
        font-size: 2vh;
    }
.contenedor-padre-vender-skins {
  
    border: 1px solid red;
  width: 100%;
}

    .contenedor-padre-vender-skins p {
        font-size: 1vh;
    }

    .background-img-vender-skins {
      
        min-height: 89vh;
    }

}