@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400&family=Train+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-blanco:#fefefe;
  --color-rosa:#ff0042;
   --color-verde:#01ec96;
   --color-fondo:#1a1819;
  /* background-image:url(./img/Fondo-Blazer.jpg); */
  background-image:url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2FFondo-Blazer.jpg?alt=media&token=ce04dd2a-4c3d-467e-8aee-a5db77332d60);

  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position: center; */
  background-size: cover;
  min-height: 100vh;
  max-width: 99.5vw !important;
  --font-all:'Noto Sans JP',
  sans-serif;
  font-family:'Noto Sans JP',
  sans-serif !important;
  overflow-y: scroll;
  scroll-behavior: smooth !important;
}
html {
  scroll-behavior: smooth !important;
}
.fondo{
  margin: 0;
  font-family: -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-rosa:#dc0c42;
  --color-verde:#01ec96;
  --color-fondo:#1a1819;
  --font-all:'Noto Sans JP',
  sans-serif;
  background-image:url(../../static/media/inicio2.4d77a374.jpg);
  background-position: center
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-colorTextSecondary {
  color: rgb(255 255 255 / 54%) !important;
}
.MuiTypography-body2{
  font-family: 'Roboto Mono',
  monospace !important;
  font-weight: 500 !important;
      font-size: 13px !important;
}
.MuiTypography-h5{
      font-size: 15px !important;
  font-family: "Roboto" ,
  "Helvetica",
  "Arial",
  sans-serif;
  font-weight: 600 !important;
  line-height: 1.334 !important;
}
.MuiCardContent-root{
  padding: 5px !important;
}
.MuiCardActions-root{
  padding: 7px !important;
}

.MuiAvatar-img{
  object-fit: unset !important;
}

.MuiCardMedia-root{
      background-size: contain !important;
}

.MuiCardHeader-root{
  flex-direction: column !important;
   padding: 0px !important;
}
.MuiCardActions-root{
  justify-content: center;
}

.btn-primary {
  background-color: #000000 !important;
  border-color: var(--color-rosa) !important;
  font-family: 'Roboto Mono';
}

.makeStyles-root-1{
  border-radius: 9px !important;
}

.MuiSlider-root {
color: var(--color-rosa) !important;
}

.navbar-light .navbar-nav .nav-link{
  color: white ;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link{
  color: var(--color-rosa) !important;
}

.navbar-dark .navbar-nav .nav-link{
  color: white !important;
}

@media (max-width: 1200px) {
  .MuiStepper-root{
        width: 43vw;
  }
  body{
    background-position: center;
  }
}


.carousel-control-prev-icon{
  background-image: url(../../static/media/reshot-icon-right-arrow-button-YAB8GEM7SD-c5f61.8fbaefa6.svg) !important;
  transform: rotate(180deg) !important;
}
.carousel-control-next-icon{
  background-image: url(../../static/media/reshot-icon-right-arrow-button-YAB8GEM7SD-c5f61.8fbaefa6.svg) !important;
}

.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


::-webkit-scrollbar {
    width: 0.5vw !important;
  margin: 0px;
  padding: 0px;
}
::-webkit-scrollbar-track {
  background: #1a1819;

}

::-webkit-scrollbar-thumb {
  background: var(--color-rosa);
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
    width: 5px !important;

}
.select-category{
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 2vh 0vw;
}
.nube-tipos{
  display: flex;
  flex-direction: row;
}
.contenedor-tienda{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.contenedor-tienda h3{
  margin-top: 4vh;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
}

.tienda-boton-ver-mas{
  margin: 10px;
}

.tienda-no-results-label{
  font-size: 15px;
  margin: 10vh;
}


.chip-mater{
  padding: 10px 10px !important;
  margin: 10px 10px;
}

.contenedor-carrito{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1vw;
}
.img-item-carrito{
  width: 5vw;
  border-radius: 5px;
  padding: 1vh;
  background-color: rgba(255, 255, 255, 0.83);
}
.img-item-carrito:hover {
  transform: scale(1.5);
  background-color: transparent;
}

.title-item-carrito{
  color: var(--color-blanco);
  font-size: 16px;
}
.precio-item-carrito {
  font-size: 18px;
  color: var(--color-blanco);
}

.carrito-conteiner{
  padding: 30px;
  background: rgba(0, 0, 0, 0.412);
}

.precio-total-carrito {
  padding-top: 20px;
  width: 30vw;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.precio-total-carrito p{
  font-size: 18px;
  padding: 0px 5px;
  font-weight: 500;
}
.precio-total-carrito span {
   font-size: 22px;
   font-weight: 500;
   padding: 5px;
}

.spinner-contenedor{
  min-height: 70vh;
  padding-top: 30vh;
}
.no-more-items-text{
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.img-weapon{
  max-width: 6vw !important;
  /* min-width: 6vw !important; */
}
.img-otro{
  width: 90%;
}

.contenedor-tipos-img {
  max-width: 7vw;
  min-width: 7vw;
  padding: 1vh 1vw;
   margin: 0vh 0.3vw;
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   justify-content: flex-end !important;
   background: #464646;
   border-radius: 2px;
    height: 8vw;
    cursor: pointer;
}
.contenedor-tipos-img h1{
  padding-top:  10px;
  font-size: 2vh;
  color: white;
  font-weight: 400;
    cursor: pointer;

}

.contenedor-tipos-img img {
  /* padding-top: 10px; */
  font-size: 4vw !important;
    cursor: pointer;

}

.contenedor-tipos-img:hover {
  background: var(--color-rosa) !important;
}


.hover-item{
   background: var(--color-rosa) !important;
}



@media (min-width: 1450px) {
  .margin-max-resolution {
    margin: 0px 10vw;
  }
}

.rango-precios-izquierda{
  background: #464646;
  /* padding: 20px; */
  padding-top: 50px;
  border-radius: 2px;
  /* height: 100px; */
  height: 8vw;
   padding: 1vh 1vw;
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   justify-content: flex-end !important;

}
.container-buscador{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.form-cont-search{
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  .contenedor-tipos-img h1 {
    font-size: 0.5vh;
  }

  .contenedor-tipos-img{
    height: 10vw;
  }

  .rango-precios-izquierda {
    padding-top: 10px;
    height: 10vw;
  }

  .img-weapon {
    max-height: 6vh !important;
  }

}
.galeria-contenedor{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 50px;
}
@media (max-width: 1200px) {
    .galeria-contenedor {
        grid-template-columns: repeat(4, 1fr);
    }
}
.imgCard {
    width: 50px;
}
.sticker-container{
    position: relative;
}

.sticker-container:hover .sticker-info{

    display: block;
}
.sticker-container .sticker-info{
    display: none;
    z-index: 10;
    position: absolute;
    top: 10px;
    width: 100%;
    border-radius: 3px;
    border: solid 1px ;
    /* height: 30px; */
    background-color: white;
    font-size: 0.6rem;

}
.sticker-container .sticker-icon{
     display: block; 
    width: 100%;
    display: flex;
    justify-content: center;
       
}
.sticker-container .sticker-icon .sticker{
    position: absolute;
    top: -30px;
}
.sticker-container .sticker-icon img{
     /* display: block; */
     /* position: relative; */
    width: 38px;
    height: 30px;
}

.MuiCardHeader-content{
    display: flex;
    align-items: center;
}

.item-disponible{
    background: #ff0000a6;
    color: #ffffff;
    font-size: 15px;
    font-family: 'Roboto Mono',
    monospace;
    /* width: 195px; */
    position: relative;
    
}
.item-disponible-true{
    background: #003f00a6;
    color: #ffffff;

    /* font-size: 15px; */
    font-family: 'Roboto Mono',
    monospace;

    position: relative;

}

.item-precio{
        color: #000000;
        font-size: 23px;
        font-family: 'Roboto Mono';
        font-weight: 400;
        border-radius: 7px;
        /* display: flex; */
        /* flex-direction: row; */
        /* justify-content: space-between; */
}

/* .item-precio span{
    font-weight: 200;
    font-weight: 400;
} */
.inspect img{
    width: 110%;
    
}
.inspect:hover img{

    background-color: rgb(224, 205, 205);
}

.media{
      height: 0;
      padding-top: 56.25%;
      background-color: linear-gradient(180deg, rgba(0,0,0,0.4598214285714286) 79%, rgba(255,0,0,0.4234068627450981) 91%);
}
.media :hover{
     transform: scale(1.3);
    }

.rootCard{
        padding: 5px;
        max-width: 195px;
        margin: 10px;
        padding: 10px;
        background: "#ffffffbd";
        font-family: "Roboto Mono monospace";
        font-weight: 700 !important;
        text-transform: uppercase;
        color: "#212121";
        text-shadow: 1px 1px #01ec9629;
    }

    .rootCard:hover{
         box-shadow: 1px 4px 20px 3px #212121;
    }
     .rootCard:hover img{
transform: scale(1.3),
     }
.letra-generica{
    font-size: 18px;
    font-weight: 700;
    color: var(--color-rosa);
    letter-spacing: 1px;
}

.letra-generica span{
    font-size: 18px;
    color: var(--color-verde);
}

.fondo-modal{
    background: var(--color-fondo);
}

.letra-Modal-titulo {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-rosa);
    letter-spacing: 1px;
    text-transform: uppercase;
}
.contenedor-titulo-tienda{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background: #131313; */
}

.titulo-tienda{
    font-size: 50px;
}

.box{
    position: relative;
    width: 400px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box h2{
    color: #fff;
    font-size: 3em;
    font-weight: 800;
    letter-spacing: 0.1em;
    /* text-shadow: 0 0 5px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 60px #01ec96; */
   font-family: 'Train One',
   cursive;
    text-transform: uppercase;
}
.lightbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    border-radius: 10px;
    background: #fff;
    z-index: 2;
    /* box-shadow: 0 0 10px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 80px #01ec96,
    0 0 120px #01ec96; */
    animation:  animatelightbar 5s linear infinite;
}
@keyframes animatelightbar{
    0%,5%
    {
        transform: scaleY(0) translateX(0);
    }
    10% {
        transform: scaleY(1) translateX(0);
    }
    90% {
        transform: scaleY(1) translateX(calc(400px - 10px));
    }
    95%,100% {
        transform: scaleY(0) translateX(calc(400px - 10px));
    }
}
.topLayer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #131313;
    animation: animatetopLayer 10s linear infinite;
}

@keyframes animatetopLayer {

    0%,
    2.5% {
        transform: translateX(0);
    }

    5%{
        transform:translateX(0);
    }

    45%{
        transform:  translateX(100%);
    }

    47.5%,50%{
        transform: translateX(100%);
    }

    50.001%,52.5%
    {
        transform: translateX(-100%);
    }

     55% {
         transform: translateX(-100%);
     }

     95% {
         transform: translateX(0%);
     }

     97.5%,100% {
         transform: translateX(0%);
     }
}






.titulodePagina-v2  {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}


.titulodePagina-v2 h2{
 position: relative;
 margin: 0;
 padding: 0;
 height: 50px;
overflow: hidden;
}

.titulodePagina-v2 h2 span{
    display: flex;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
}

.titulodePagina-v2 h2:hover span {
    animation: shuffle steps(10) 3s infinite ;
}


@keyframes shuffle {
    0%{
        transform: translateY(0px);
    }
     100% {
         transform: translateY(-500px);
     }
}



.demo h2 {
   font-size: 3em !important;
   font-weight: 500 !important;
   letter-spacing: 0.1em !important;
   color: #313030 !important;
   /* text-shadow: 0 0 5px #01ec96, 0 0 20px #01ec96, 0 0 40px #01ec96, 0 0 60px #01ec96; */
   font-family: 'Montserrat',
   sans-serif;
   text-shadow: 8px 3px #00000042;
   text-transform: uppercase !important;

     /* text-shadow: 0 0 5px #01ec96,
    0 0 20px #01ec96,
    0 0 40px #01ec96,
    0 0 60px #01ec96; */
}




.inputRange-precios{
    padding: 0px 3vw;
    width: 40vw;
}

.titulo-slieder{
    /* font-size: 13px !important; */
  font-size: 2vh !important;
   color: white;
   font-weight: 400;
   cursor: pointer;
}

.contenedor-silder-titulo{
    display: flex;
    flex-direction: column;
}
@media (max-width: 1200px) {
    .titulo-slieder {
        font-size: 0.5vh !important;
    }


}
.closeIcon{
    cursor: pointer;
  }


  .closeIcon:hover{
    color: #ff0042;
  }
  

.order-menu{
    
    
    margin:  auto 0px auto -100%;
    @media all and (max-width: 994px){

            font-size: 1.3rem;       
         margin-left: -40px;
        
    }
}


.dropdown  {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    /* color: $text; */
   
    background-color: transparent;
    border: 0;
    width: 100%;


}

.dropdown:hover{
    color: #ff0042;
    /* background-color: $text; */
    font-weight: bold;

}
.dropdown-menu{
      
    /* background-color: $homepage-dark; */
    /* color: $text; */
    cursor: pointer;
    box-shadow: 0px 3px 10px black;
    min-width: 5rem;

}
.nav-menu-items{
    padding: 5px;
}
.nav-menu-items:hover{
                
    color: #ff0042;
    background-color: inherit;
}
.home-contenedor-padre{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth !important;
   overflow-x: hidden;
}

.contenedor-home-padre-unico{
    display: flex;
    flex-direction: column;
}
.first-item-home {
    z-index: 100;
     width: 100%;
     height: 100%;
     /* background: url(../../img/inicio2.jpg); */
     background: url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2Finicio2.jpg?alt=media&token=4ffa7f83-19b0-49c1-8a1b-41453d12e54e);
     background-repeat: no-repeat;
     background-size: 100%;
     background-position: center;
     }

.first-item-home img{
height: 100vh;
width: 100vw;
z-index: -1;
 background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
}
.first-item-home-difum {
     background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
}

.flecha-bajar{
    position: absolute;
    bottom: 1vh;
    left: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-rosa);
    border-radius: 5px;
     border: 1px solid rgba(0, 0, 0, 0.433);
     padding: 5px;
     box-shadow: 0px 0px 7px 1px;
}
.flecha-bajar:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
    padding: 5px;
}
.subir-sin-estilos{
     /* position: absolute; */
     margin: 30px 0px;
     bottom: 1vh;
     left: 50vw;
     display: flex;
     align-items: center;
     justify-content: center;
     background: var(--color-rosa);
     border-radius: 5px;
     border: 1px solid rgba(0, 0, 0, 0.433);
     padding: 5px;
     box-shadow: 0px 0px 7px 1px;
}
.subir-sin-estilos:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
    padding: 5px;
}

.bajar-sin-estilos{
    text-decoration: none;
    color: black;
}

.bajar-sin-estilos:hover {
    text-decoration: none;
    color: var(--color-rosa);
    cursor: pointer;
}


.background-white-home{
   /* background: #fcfcfc; */
   height: 90vh;
   width: 100vw;
}

.boton-tienda {
    min-width: 5vw;
    position: absolute;
    bottom: 1vh;
    left: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.919);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.433);
    padding: 0.6vh 2vw;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-family: var(--font-all);
    -webkit-clip-path: polygon(85% 0, 93% 50%, 85% 100%, 9% 100%, 14% 51%, 9% 0);
            clip-path: polygon(85% 0, 93% 50%, 85% 100%, 9% 100%, 14% 51%, 9% 0);

}

.boton-tienda:hover {
    background: rgba(0, 0, 0, 0.829);
    border-radius: 5px;
    border: 1px solid var(--color-rosa);
}



@media (max-width: 1200px) {
    .background-white-home {
   background: #fcfcfc;
        height: 35vh;
    }

    .first-item-home {
        background-position: top;
    }
    .flecha-bajar{
        /* bottom: 60vh; */
        bottom: 60%;
    }
    .boton-tienda{
        /* bottom: 60vh; */
        bottom: 60%;
    }
}

@media (width: 500px) {
.background-white-home {
    height: 34vh;
}
}
.img__carousel {
    object-fit: fill;
    height: 40vh;
    width: 100%;
}

.carrousel-contenedor-home{
    margin: 0px 0px;
    width: 100vw;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 48%, rgba(0, 0, 0, 0.8911939775910365) 99%); */
}
.carrousel-contenedor-home h1{
    margin: 0;
    font-size: 2em !important;
    font-weight: 500 !important;
    letter-spacing: 0.1em !important;
    color: #fe0040 !important;
    /* text-shadow: 0 0 5px #01ec96, 0 0 20px #01ec96, 0 0 40px #01ec96, 0 0 60px #01ec96; */
    font-family: var(--font-all);
    background: #000000;
    text-transform: uppercase !important;
    padding: 2vh 0px;
}

.carrousel-contenedor-only{
    margin-bottom: 0px;
}
.redes-carrousel-each-twitch {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0vh;
    right: 0vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-twitch:hover {
   padding: 3vh 3vw;
   background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */
.redes-carrousel-each-insta {
    height: 8vh;
     width: 13vw;
    position: absolute;
    bottom: 0vh;
    right: 23vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-insta:hover{
   padding: 3vh 3vw;
   background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */
.redes-carrousel-each-tw {
    z-index: 0;
    height: 8vh;
     width: 13vw;
    position: absolute;
    bottom: 0vh;
    right:8vw;
    color: white;
    font-size: 30px;
}
/* .redes-carrousel-each-tw:hover{
    padding: 3vh 3vw;
    background: linear-gradient(0deg, rgba(255, 250, 250, 0.8631827731092436) 0%, rgba(255, 255, 255, 0) 41%, rgba(255, 255, 255, 0) 100%);
} */


.contenedor-de-tweets{
min-height: 100vh;
max-height: 90vh;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
margin: 3vw;
}



@media (max-width: 1200px) {
    .img__carousel {
        height: 20vh;
    }

    .carrousel-contenedor-home {
    }
    .contenedor-de-tweets{
min-height: 50vh;
    }
}
.horizontal {
    display:block;
    float:left;
    padding:0 10px;
    
}
.horizontal a:hover{
 color: var(--color-verde) !important;
}
.ul{
    align-items: center;
    display: inline-flex;
}

.text-white-nav{
    color: var(--color-rosa) !important;
}

.text-white {
    color: white !important;
}

.icon__social{
    margin-top: 10px;
    font-size: 30px;
}

.color-ngro li a{
 color: var(--color-fondo) !important;

}

.color-ngro li a:hover{
    color: var(--color-rosa) !important;

}

.contenedor-socials {
    display: flex;
}
.contenedor-socials-noflex-none{
    display: flex;

}


@media (max-width: 1200px){
    .contenedor-socials {
        display: none !important;
    }
}

.Contenedor-tweet {
  margin: 25px;
  padding: 30px;
  border: 1px solid #ff2828;
  background-color: #161616;
  width: 500px;
}
.tarjeta{
  min-width: 390px !important;
}


.Contenedor {
  display: flex;
  flex-direction: row;
}

@media(max-width:400px){
  .Contenedor-tweet {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .tarjeta {
    min-width: 190px !important;
min-height: auto;

  }
}
.contenedor-covert-info-padre img{
    width: 100%;
    height: 35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

@media (max-width: 1200px) {
   .contenedor-covert-info-padre img {
             height: 14vh;
   }
}
.cerrar-chat-text{
font-size: 9px;
position: fixed;
bottom: -16px;
color: white;
right: 1px;
background: #00000094;
padding: 5px;
cursor: pointer;
}
.mrauto{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
}
.mrauto a{
    
    text-decoration: none;
    color: var(--color-blanco);
    padding: 10px;
    font-size: 18px;
    font-family: var(--font-all);
    font-weight: 400;
    /* text-transform: uppercase; */
}
.mrauto a:hover {
    text-decoration: none;
    color: var(--color-rosa);
    padding: 10px;
}
.mrauto h1{
font-family: var(--font-all);
font-size: 2.5vh;
}
.boton-login-navbar h1{
font-family: var(--font-all);
font-weight: 600;
font-size: 2vh;
background: #ff0042;
padding: 1vh;
border-radius: 9px;
letter-spacing: 1px;
}

.letraBlanca{
    color: white ;
    font-family: var(--font-all) !important;
}

.color-navbar{
    background-color: #000000 !important;
    border-bottom: 2px solid var(--color-rosa);
}
.boton-login-navbar{
    color: var(--color-blanco);
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}
.boton-login-navbar:hover {
    text-decoration: none;
    color: black;
}

.modal-login .modal-content {
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 48%, rgba(0, 0, 0, 0.8911939775910365) 99%); */
    background: white;
}
.modal-login{
    min-width: 100vw !important;
    min-height: 100vh !important;
}
.hover-foto:hover {
    background: var(--color-rosa) !important;
    padding: 1px;
    border-radius: 50%;
}

.logo-izquierda{
    display: flex;
    flex-direction: row;
}
.login-container{
    /* background: radial-gradient(circle, rgba(191, 46, 46, 0) 2%, rgba(232, 70, 70, 0) 48%, rgba(221, 101, 101, 0.891) 99%); */
    padding: 4vw;
}

.login-container h1 {
     text-decoration: none;
     color: white;
     padding: 10px;
         font-family: var(--font-all);
letter-spacing: 1px;
     font-weight: 600;
     text-transform: uppercase;
}

.login-container h3 {
    text-decoration: none;
        color :white;
    font-size: 14px;
    font-family: var(--font-all);
    font-weight: 400;
}

.btn-log{
    cursor: pointer;
    color: var(--color-rosa);
}
.btn-log:hover{
    
    color: #ff0044c7;
}

.politicas-uso{
    color: var(--color-rosa);
    cursor: pointer;
}
.politicas-uso:hover{
    color: #ff0044c7;
   
}
.contenedorBotones{
    width: 435px;
}
.separador-btn {
    margin: 10px !important;
}
 
 .input-container{
    margin-bottom: 3px;
 }
 
 input{
    padding: 5px;
  
    /* display: none; */
}

.error-messaje{
    color: red;
}
.h1-items-carrito{
    font-size: 12px;
    margin: 0px 1vw;
}

.contenedor-carrito-beta{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1vw;
}

.contenedor-carrito-beta img{
    max-width: 3vw;
}

.boton-eliminar-carrito{
    cursor: pointer;
    padding: 1px;
    border-radius: 3px;
}

.boton-eliminar-carrito:hover {
    background-color: var(--color-rosa);
}

.contenedor-scroll-items-carrito{
    scroll-behavior: smooth;
    overflow-y: scroll;
}
.precio-total-carrito{
    font-size: 20px;
    float: left;
}

.link-drawer {
    text-decoration: none;
    color: #72ab3a;
    font-weight: 500;
}

.link-drawer:hover {
    text-decoration: none;
}
.formulario-admin-datos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.contenedor-admin-max{
min-height: 100vh;
}

.formulario-contenedor-admin-datos{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    margin: 19px;
background-color: black;
padding: 20px;
}
.contenedor-panel{
    padding: 30px;
}
.img-panel{
    width: 100px;
}

.tabla-admin-panel{
    padding: 60px;
}

.contenedor-items-a-subir{
    background: #0000005e;
    padding: 30px;
    margin: 10px 40px;
}
.contenedor-padre-vender-skins{
    background-color: rgba(0, 0, 0, 0.911);
  padding-top: 5vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid red;
  border-left: 1px solid red;
  font-family: var(--font-all) !important;
      padding: 4vh 0vw;
}
.background-img-vender-skins{
 /* background-image:url(../../img/venderskins.jpeg); */
 background-image: url(https://firebasestorage.googleapis.com/v0/b/stgo-76200.appspot.com/o/fotos%2Fvenderskins.jpeg?alt=media&token=2cb0666c-55fd-403f-98ba-cb9efe16dfe0);
 background-repeat: no-repeat;
 background-attachment: fixed;
 background-position: center;
 background-size: cover;
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 85vh;

}
.contenedor-padre-vender-skins a {
    color: var(--color-rosa);
    font-family: var(--font-all);
}
.contenedor-padre-vender-skins h1{
    font-size: 7vh;
color: white;
font-family: var(--font-all);
}

.contenedor-padre-vender-skins p {
    margin: 19px;
    font-size: 3vh;
    color: white;
    font-family: var(--font-all);
}

.contenedor-padre-vender-skins img {
    max-width: 70vw;
}

.pagos-logos{
    max-width: 50vw !important;
}
.achicar-img{
    width: 50vw;
}


@media (max-width: 1200px) {
    .contenedor-padre-vender-skins h1 {
        font-size: 2vh;
    }
.contenedor-padre-vender-skins {
  
    border: 1px solid red;
  width: 100%;
}

    .contenedor-padre-vender-skins p {
        font-size: 1vh;
    }

    .background-img-vender-skins {
      
        min-height: 89vh;
    }

}
.contenedor-contactos-logo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.contenedor-contactos-logo a img{
    max-width: 6vw;
    margin: 0px 4vw;
}

.contenedor-contactos-logo a img:hover {
    border-radius: 20%;
    padding: 6px;
}
.footerConteiner {
  color: aliceblue;
  padding: 25px;
  padding-bottom: 0px;
}
.textMidle {
  font-size: 11px;
  font-weight: 100;
  font-weight: 400;
  color: rgb(87, 87, 87);
}
.textMidle a{
  
  color: rgb(87, 87, 87);
}
.footer {
  background-color: #0d0d0d;
  height: 105px;
  
}

.copyright {
  display: flex;
  justify-content: center;
}
.socialFooter {
  display: flex;
  justify-content: center;
  
}

.footer__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desarrollado {
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .footer {
    height: 100%;
  }
  .socialFooter {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.contenedor-padre-preguntas{
    margin: 3vh 10vw;
    font-family: var(--font-all);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white !important;
}
.contenedor-padre-preguntas h1 {
    color: var(--color-fondo);
    font-size: 30px;
    margin: 20px 0px;
    font-weight: 800;
}
.contenedor-padre-preguntas a{
    color: var(--color-rosa);
}
.contenedor-padre-preguntas a:hover {
    color: var(--color-verde);
    font-weight: 600;
}
.ul-items li{
-webkit-text-decoration: dashed;
        text-decoration: dashed;
list-style: auto;
text-align: start;
font-size: 18px;
}
.ul-itemsv3{
        padding: 20px 0px;
}
.ul-itemsv3 li {
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    list-style: none;
    text-align: start;
    font-size: 18px;
}
.ul-itemsv2 li {
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    list-style: auto;
    text-align: start;
    font-size: 16px;
}

.contenedor-social{
    padding: 20px;
}

.contenedor-img-pagos{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-pagos{
    max-width: 35vw;
}

.img-cuentas-pf{
    width: 30px;
    padding: 3px;
}


@media (max-width: 1200px) {
    .contenedor-padre-preguntas {
        justify-content: start;
    }
}
.contenedor-pagar-abuelo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-all);
    font-weight: 300 !important;
    min-height: 100vh;
}


.contenedor-pagar-padre {
    background: white;
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    box-shadow: 1px 1px 19px 3px black;
}
.contenedor-pagar-padre h1{
    font-family: var(--font-all);
    font-size: 4vh;
    color: black;

}
.card-item-pagar{
border-top: 1px solid rgba(73, 73, 73, 0.72);
margin-top: 3vh;
padding-top: 1vh;
display: flex;
flex-direction: row;
/* align-items: center; */
}
.card-item-pagar img{
    max-width: 7vw;
    max-height: 6vw;
}
.card-item-pagar img:hover {
    transform: scale(1.3);
}

.contenedor-data-item{
    padding: 2vh 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.contenedor-data-item h1{
    font-size: 2vh;
}
.contenedor-data-item h2 {
    font-size: 2vh;
}

.contenedor-data-item h3 {
    font-size: 1.7vh;
    color: var(--color-rosa);
    font-weight: 300;
}
.contenedor-data-item h4 {
    font-size: 1.7vh;
    color: var(--color-verde);
    font-weight: 300;
}

.contenedor-data-item h6 {
    font-size: 1.3vh;
}


.contenedor-subtotal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contenedor-descuento h1 {
   font-size: 2vh !important;
   color: rgba(255, 0, 0, 0.514);
}

.contenedor-descuento h2 {
    font-size: 2vh !important;
    color: rgba(255, 0, 0, 0.514);
}

.contenedor-descuento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contenedor-izquierda{
    padding: 3vw;
}
.contenedor-izquierda h2{
    font-size: 2vh;
    padding: 5px;
}

.contenedor-derecha {
    padding: 3vw;
}

.contenedor-derecha h1{
    font-size: 2.5vh;
    padding: 1vh;
}
.contenedor-subtotal h1 {
    font-size: 2.5vh;
}
.contenedor-subtotal h2 {
    font-size: 3.5vh;
    font-weight: 700;
}

.contenedor-cupon{
    padding: 1vw;
}

.contenedor-cupon h2{
    font-size: 16px;
}

.contenedor-finalizar-detalle{
    display: flex;
    flex-direction: row;
}


@media (max-width: 1200px) {
    .contenedor-pagar-padre h1 {
        font-size: 1.5vh !important;
    }
    .contenedor-subtotal h2 {
        font-size: 2vh;
        font-weight: 300;
    }

    .contenedor-pagar-abuelo {
        min-height: 75 !important;
    }
}
.ul-items li {
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    list-style: none;
    text-align: start;
    font-size: 18px;
}
.contenedor-padre-finalizar-all{
    display: flex;
    flex-direction: column;
}
.contenedor-flex-ro{
display: flex;
flex-direction: row;
}

.detalles-finalizar-compra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.detalles-finalizar-compra h1 {
    font-size: 1.3vw;
}

.item-finalizar-compra {
    border-top: 1px solid rgba(106, 106, 106, 0.72);
    color: rgba(39, 39, 39, 0.72);
    padding-top: .4vh;
}

.item-finalizar-compra h6 {
    font-size: 14px;
}

.items-juntos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contenedor-step-3 h1{
 font-size: 16px;
}
.contenedor-step-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.MuiStepIcon-root.MuiStepIcon-active{
    color: var(--color-rosa) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed{
    color: #3fb548 !important;
}

.MuiButton-containedSecondary{
    background-color: var(--color-rosa) !important;
}

.comprobante {
    border-top: 1px solid rgb(97, 97, 97);
    padding: 3vw;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-all) !important;
}
.comprobante-body{
    padding: 3vw 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.comprobante h1 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
}

.comprobante h2 {
    font-size: 14px;
    text-align: center;
}



.contenedor-data-item-comprobante h1 {
    font-size: 12px;
}

.contenedor-data-item-comprobante h2 {
    font-size: 16px;
    margin: 0px 10px;
}

.contenedor-data-item-comprobante h3 {
        font-size: 16px;

    color: var(--color-rosa);
    font-weight: 300;
    margin: 0px 10px;

}

.contenedor-data-item-comprobante h4 {
        font-size: 13px;

    color: var(--color-verde);
    font-weight: 300;
    margin: 0px 10px;

}

.contenedor-data-item-comprobante h6 {
    font-size: 13px;
}

.precio-verde-grande span{
font-size: 20px;
font-weight: 500;
color: #3fb548;
padding: 4px;
}

.metodos-pago-head{
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 16px;
}
.parrafo-info-pago{
    font-size: 12px;
}

@media (max-width: 1200px) {
  .detalles-finalizar-compra {
      display: none;
  }
  .comprobante {
      width: 50vw;
  }
  .contenedor-data-item-comprobante h1 {
      font-size: 1vh;
  }
  .contenedor-data-item-comprobante h2 {
      font-size: 1vh;
  }

  .contenedor-data-item-comprobante h4 {
      font-size: 1vh;
  }
  
}
